<template>
  <Draggable
    tag="tbody"
    :list="data.dataSource"
    @change="onChange"
    @start="handleStart"
    @end="handleEnd"
    v-bind="options"
    ref="wrapperRef"
    :disabled="data.disabled"
  >
    <slot></slot>
  </Draggable>
</template>
<script>
import Draggable from "vuedraggable";
const dragItemCls = "dragged-item";
const backgroudColor = "#fcfcfc";
export default {
  components: {
    Draggable,
  },
  inject: ["data"],
  data() {
    return {
      isDragging: false,
      options: {
        delay: 0,
        fallbackTolerance: 10,
        forceFallback: true,
        fallbackClass: dragItemCls,
      },
    };
  },
  methods: {
    handleStart(e) {},
    handleEnd(e) {},
    setDragItem() {
      const wrapperRef = this.$refs.wrapperRef["$el"];
      if (!wrapperRef) {
        return;
      }
      const dragItem = wrapperRef.getElementsByClassName(dragItemCls)[0];
      if (!dragItem) {
        return;
      }
      dragItem.children.forEach((node) => {
        node.style.backgroundColor = backgroudColor;
        node.style.background = backgroudColor;
        node.style.color = "#000";
      });
      dragItem.style.transform = "translate(0,0)";
      const scroller = wrapperRef.querySelector(
        ".ant-table-scroll .ant-table-body"
      );
      if (!scroller) {
        return;
      }
      const left = scroller.scrollLeft;
      dragItem.style.transform = `matrix(1, 0, 0, 1, ${left}, 0)`;
    },
    onChange(evt) {},
  },
};
</script>
