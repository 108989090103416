<template>
  <div class="text-search border-1 top--10">
    <div class="input-search-wrap">
      <a-input-search
        @search="handleSearch"
        @pressEnter="handleEnter"
        v-model="inputValue"
      />
    </div>
    <div class="list-wrap">
      <ul class="list">
        <li
          class="item"
          v-for="user in filterUsers"
          :key="user.id"
          @click="handleClick(user)"
        >
          <img class="avatar" :src="user.head_img" />
          <span class="name">{{ user.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
let avatar =
  "https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.174267730247144261640329585.425.png";
export default {
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      inputValue: "",
      users: [],
    };
  },
  mounted() {
    const { api } = this.option;
    api().then((res) => {
      let data = res.data || res;
      this.users = data.map((u) => {
        const head_img = u.head_img || avatar;
        return {
          ...u,
          name: u.supplier_name?u.supplier_name:`${u.last_name || ""}${u.first_name || ""}`,
          head_img,
        };
      });
    });
  },
  methods: {
    handleEnter() {
      this.ok();
    },
    ok(val) {
      const { ok } = this.option;
      ok && ok(val || this.inputValue);
    },
    handleClick(user) {
      this.ok(user);
    },
    handleSearch() {
      this.ok();
    },
  },
  computed: {
    filterUsers() {
      if (!this.inputValue) {
        return this.users;
      }
      return this.users.filter((u) => u.name.includes(this.inputValue));
    },
  },
};
</script>

<style lang="scss" scoped>
.text-search {
  background: #fff;
  background-color: #fff;
  position: absolute;
  z-index: 998;
  min-width: 246px;
  padding: 13px 21px;
}
.input-search-wrap {
  background: #fff;
  background-color: #fff;
  // border: 1px solid #e0e0e0;
}
.list-wrap {
  //border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  .list {
    margin-bottom: 0px;
    max-height: 200px;
    overflow: auto;
  }
  .item {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e0e0e0;
    &:hover {
      background-color: #eee;
    }
    .name {
      width: 154px;
      font-size: 16px;
      color: #7f7e79;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .avatar {
      height: 36px;
      width: 36px;
      margin-right: 12px;
    }
  }
}
</style>
