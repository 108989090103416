<template>
  <div class="filterState absoulte top--10 border-1">
    <div
      class="item pointer hvr-bg-color"
      :class="idx == list.length - 1 ? null : 'border-bottom'"
      v-for="(item, idx) in list"
      @click="filterState(idx)"
      :key="idx"
    >
      <span class="circle" :class="item.classname"></span>
      <span>{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    filterState(idx) {
      this.$emit("click", idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.filterState {
  width: 117px;
  background-color: #fff;
  > div {
    width: 100%;
    height: 45px;
    line-height: 45px;
    padding-left: 11px;
  }
  .boder-bottom {
    border-bottom: 1px solid #b1b1b1;
  }

  .circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    display: inline-block;
    background-color: #c6e4fe;
    margin-right: 8px;
    font-size: 16px;
    &.success {
      background-color: #63d8bb;
    }
    &.danger {
      background-color: #fc4f74;
    }
  }
}
</style>
