<template>
  <div v-if="mVisible">
    <a-range-picker
      @change="(val, timeString) => $emit('change', { val, timeString })"
      @openChange="handleStartOpenChange"
      :open="mVisible"
    />
  </div>
</template>

<script>
import bus from "../bus";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 0,
    },
    ok: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return { mVisible: false };
  },

  mounted() {
    this.modify();
    bus.$on("open", (id) => {
      if (id == this.id) {
        this.mVisible = true;
      }
    });
  },
  methods: {
    modify() {
      this.$nextTick(() => {
        const datePicker = document.getElementsByClassName(
          "ant-calendar-picker-container"
        )[0];
        if (!datePicker) {
          return;
        }
        datePicker.addEventListener("mouseleave", () => {
          this.mVisible = false;
          bus.$emit("close", this.id);
        });
      });
    },
    handleStartOpenChange() {
      this.mVisible = false;
      bus.$emit("close", this.id);
    },
  },
  updated() {
    this.modify();
  },
};
</script>

<style lang="scss"></style>
