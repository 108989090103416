<template>
  <div class="column-title" v-if="type !== 'date'">
    <a-popover placement="bottom" trigger="hover" ref="popoverRef">
      <template slot="content">
        <!-- <div class="popover" v-show="mVisible" ref="ref">
          <slot></slot>
        </div> -->
        <div @mouseleave="handleOut">
          <slot></slot>
        </div>
      </template>
      <span class="filter-trigger" @mouseenter="handleMouseEnter">
        <div class="title">
          <span class="mr-6">{{ title }}</span>
          <i
            class="el-icon-arrow-down"
            :class="mVisible ? 'collapse' : ''"
            @click="handleClick"
          ></i>
        </div>
      </span>
    </a-popover>
  </div>
  <div class="column-title" @mouseenter="handleMouseEnter" v-else>
    <div class="title">
      <span class="mr-6">{{ title }}</span>
      <i
        class="el-icon-arrow-down"
        :class="mVisible ? 'collapse' : ''"
        @click="handleClick"
      ></i>
    </div>
    <div class="popover" v-show="mVisible" @mouseleave="handleOut">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import bus from "../bus";
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mVisible: false,
    };
  },
  mounted() {
    bus.$on("close", (id) => {
      if (id == this.id) {
        this.mVisible = false;
      }
    });
  },
  methods: {
    handleMouseEnter() {
      this.mVisible = true;

      bus.$emit("open", this.id);
    },
    onClickOutside() {
      this.mVisible = false;
    },
    handleOut() {
      this.mVisible = false;
    },
    handleClose() {
      this.mVisible = false;
    },
    handleClick() {},
  },
};
</script>

<style lang="scss">
.column-title {
  position: relative;
  .popover {
    background: #fff;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    min-width: 100px;
  }
  .el-icon-arrow-down {
    font-size: 14px;
  }
  .collapse {
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }
}
.ant-popover {
  .ant-popover-inner-content {
    padding: 0 !important;
  }
  .ant-popover-arrow {
    display: none !important;
  }
}
</style>
