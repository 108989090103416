<template>
  <div class="n-table">
    <a-table
      ref="tableRef"
      :customRow="customRow"
      :scroll="{ x: 100 }"
      :style="{ background: '#fff' }"
      :columns="mColumns"
      :rowClassName="
        (record, index) => {
          return index % 2 == 0 ? 'grey' : null;
        }
      "
      :components="components"
      rowKey="id"
      :data-source="dataSource"
      size="small"
      :loading="loading"
      :pagination="pageInfo"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template v-for="(col, idx) in columnSlots" :slot="col.slots.title">
        <column-title
          :key="idx"
          :title="getTarget(col).label"
          :id="idx"
          :type="col.slots.type"
        >
          <div style="position: relation" class="slots">
            <div style="width: 240px" v-if="col.slots.type == 'date'">
              <date-picker
                v-if="col.slots.type == 'date'"
                :id="idx"
                @change="col.slots.ok"
              ></date-picker>
            </div>
            <search-list
              v-if="col.slots.type == 'search'"
              :option="col.slots"
            />
            <div v-if="col.slots.type == 'state'">
              <state-filter :list="col.slots.list" @click="col.slots.ok" />
            </div>
          </div>
        </column-title>
      </template>
      <template slot="action">
        <a-popover placement="leftTop" trigger="click">
          <template slot="content">
            <div class="columns-filter">
              <div class="checkbox-group">
                <a-checkbox-group
                  v-model="checkedList"
                  :options="plainOptions"
                  @change="handleChangeColumns"
                />
              </div>
            </div>
          </template>
          <span class="filter-trigger">
            <i class="iconfont icon-shenglvehao1"></i>
          </span>
        </a-popover>
      </template>
    </a-table>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import StateFilter from "./filters/state";
import SearchList from "./search-list";
import ColumnTitle from "./column-title";
import Wrapper from "./wrapper.vue";
import DatePicker from "./date-picker";
const backgroudColor = "#fcfcfc";
const dragItemCls = "dragged-item";
export default {
  components: { ColumnTitle, StateFilter, SearchList, DatePicker },
  props: {
    pagination: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    selectedRow: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedList: this.getTitleList(),
      plainOptions: this.getTitleList(),
      openRagePicker: false,
      showCreateTimeFilter: false,
      show: false,
      mColumns: [
        ...this.columns,
        {
          dataIndex: "action",
          key: "action",
          width: "40px",
          slots: { title: "action" },
        },
      ],
      pageInfo: this.pagination,
      selectedRowKeys: [],
      dataSource: this.data,
      components: {
        // props: {},
        body: {
          wrapper: Wrapper,
        },
      },
    };
  },

  created() {
    this.initColumn();
	console.log(this.columnSlots);
	console.log(this.mColumns);
  },
  computed: {
    columnSlots() {
      return this.mColumns
        .filter((c) => c.slots && c.slots.title != "action")
        .map((c) => ({ ...c }));
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newVal) {
        this.dataSource = newVal;
      },
    },
    columns: {
      handler(val) {
        this.mColumns = [...val];
        this.checkedList = this.getTitleList();
        this.plainOptions = this.getTitleList();
        this.initColumn(val);
      },
      deep: true,
    },
    pagination: {
      handler(val) {
        this.pageInfo = val;
      },
      deep: true,
    },
    selectedRow: {
      handler(val) {
        this.selectedRowKeys = val;
      },
      deep: true,
    },
  },
  methods: {
    initColumn(val) {
      const res = (val || this.columns).map((col) => {
        if (col.slots) {
          const {
            value = 0,
            visible = false,
            ok = () => {},
            title,
            list = [],
            type,
            api = () => {},
          } = col.slots;
          return {
            ...col,
            slots: {
              type,
              value,
              visible,
              ok,
              title,
              list,
              api,
            },
          };
        }
        return col;
      });
      this.mColumns = [
        ...res,
        {
          label: "action",
          dataIndex: "action",
          key: "action",
          width: "40px",
          slots: { title: "action", label: "action" },
        },
      ];
    },
    getTarget(slot) {
      return this.columns.find((c) => c.label == slot.label);
    },
    getTitleList() {
      return this.columns.map((c) => c.title || c.label);
    },
    setDragItem() {
      const table = this.$refs.tableRef["$el"];
      if (!table) {
        return;
      }
      const dragItem = table.getElementsByClassName(dragItemCls)[0];
      if (!dragItem) {
        return;
      }
      dragItem.style.width = "1200px";
      dragItem.children.forEach((node) => {
        node.style.backgroundColor = backgroudColor;
        node.style.background = backgroudColor;
        node.style.color = "#000";
      });
      dragItem.style.transform = "translate(0,0)";
      const scroller = table.querySelector(".ant-table-scroll .ant-table-body");
      if (!scroller) {
        return;
      }
      const left = scroller.scrollLeft;
      dragItem.style.transform = `matrix(1, 0, 0, 1, ${left}, 0)`;
    },
    moveRow() {
      const el =
        this.$refs.tableRef["$el"].getElementsByClassName("ant-table-tbody")[0];

      this.sortable = new Sortable(el, {
        delay: 0,
        fallbackTolerance: 10,
        forceFallback: true,
        fallbackClass: dragItemCls,
        onChoose: (evt) => {
          const { item } = evt;
        },
        onStart: (evt) => {
          this.setDragItem();
        },
        onChange: (/**Event*/ evt) => {
          const {
            newDraggableIndex,
            newIndex,
            oldDraggableIndex,
            oldIndex,
            item,
          } = evt;
          console.log(
            "onChange",
            evt,
            newDraggableIndex,
            newIndex,
            oldDraggableIndex,
            oldIndex
          );
          this.changeColor({ newIndex, oldIndex });
          this.updateDataSource(oldIndex, newIndex);
        },

        onEnd: (evt) => {
          this.reset();
        },
      });
    },
    updateDataSource(newIndex, oldIndex) {
      const res = this.dataSource.splice(oldIndex, 1);
      this.dataSource.splice(newIndex, 0, ...res);
    },
    getNode() {
      return this.$refs.tableRef["$el"].querySelector(
        ".ant-table-fixed-left .ant-table-fixed .ant-table-tbody"
      );
    },
    reset() {
      const fixedBody = this.getNode();
      fixedBody.children.forEach((node) => {
        node.style.background = "";
        node.children.forEach((ele) => {
          ele.classList.remove("transparent-important");
        });
      });
    },
    changeColor({ newIndex, oldIndex }) {
      const fixedBody = this.getNode();
      const children = fixedBody.children;
      const hoverColor = "#f0fefa";
      children.forEach((node, idx) => {
        if (idx == newIndex) {
          node.style.background = hoverColor;
        }
        node.children.forEach((ele) => {
          ele.classList.add("transparent-important");
        });
      });
      children[newIndex].style.background = hoverColor;
      children[oldIndex].style.background = "";
      console.log(
        children[newIndex].style.background,
        children[oldIndex].style.background
      );
    },
    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleChangeColumns(checkedList) {
      let res = [];
      this.columns.forEach((c) => {
        checkedList.forEach((chk) => {
          if (chk == c.label || chk == c.title) {
            res.push(c);
          }
        });
      });
      this.mColumns = [
        ...res,
        {
          dataIndex: "action",
          key: "action",
          width: "40px",
          slots: { title: "action" },
        },
      ];
    },
    onClickOutside() {
      this.show = false;
    },
    handleDisplayFilter() {
      this.show = true;
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.emit(record);
          },
        },
        render(h, props, children) {
          return <vuedraggable>{children}</vuedraggable>;
        },
      };
    },
    handleTableChange(pagination, filters, sorter) {
      this.pageInfo = pagination;
      this.$emit("change", {
        pagination,
        filters,
        sorter,
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
      this.$emit("select", selectedRowKeys);
    },
    emit(record) {
      this.$emit("click", record);
    },
  },
  provide() {
    return {
      data: this,
      // disabled: this.disabled,
    };
  },
};
</script>

<style lang="scss">
.n-table {
  background-color: #fff;
  .ant-table {
    font-size: $base-font-size;
    color: #000;
  }
  .ant-table table {
    background-color: #fff;
  }
  .ant-table-content {
    .ant-table-body {
      margin: 0 0;
    }
  }
  .ant-table-thead {
    height: 56px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.18);
  }
  .ant-table-thead > tr > th {
    font-weight: $head-font-weight;
    font-size: $base-font-size;
  }
  .action {
    position: relative;
  }
  .grey {
    background-color: rgb(249, 249, 249);
  }
  .icon-shenglvehao1:hover {
    color: var(--primaryColor);
    cursor: pointer;
  }
  .ant-table td {
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}
.columns-filter {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-group-item {
      padding: 4px 0px;
    }
  }
}

.filter-trigger {
  display: inline;
  width: 30px;
  height: 20px;
  line-height: 20px;
}
</style>
